.Home-back {
    background-image: url("../img/background1.jpeg");
    background-size: cover;
    height: 100vh;
}

.Home-front {
    background: linear-gradient(0deg, #000000e6, #fff0);
    font-family: Raleway, sans-serif;
    height: 100%;
    width: 100%;
    padding: 3rem;
    color: white;
}

.HomeN {
    font-family: Raleway, sans-serif;
    padding: 3rem 3rem 3rem 3rem;
}

