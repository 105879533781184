.BottomBar {
    height: 35vh;
    background: black;
}

.BottomText{
    font-family: Raleway, sans-serif;
    font-weight: 200;
    font-size: 1rem;
    text-decoration: white;
}

.BottomLink {
    color: white;
}
.BottomLink:hover {
    color: #717171;
}

.marquee {
    width: 100%;
    color: white;
    font-family: Raleway, sans-serif;
    font-size: 8rem;
    font-weight: 900;
    white-space: nowrap;
    overflow: visible;
    position: relative;
}

.marquee span {
    display: inline-block;
    animation: marquee 2000s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(-100%);
    }
}